<template>
<div class="agree">
    <van-nav-bar
        :title="title[this.t]"
        fixed
        left-arrow
        @click-left="$router.go(-1)"
    />
    <div class="content" v-html="content">

    </div>

</div>
  
</template>
<script>
import { Toast } from 'vant';
export default {
  name: "Agree",
  data() {
    return {
      loading: false,
      t: '',
      title: {
        user:'服务协议',
        private:'隐私条款',
        protect: '个人信息保护协议',
        photo1990: '主播拍摄知识产权归属及肖像权使用合同',
        photo4990: '主播拍摄知识产权归属及肖像权使用合同',
        mcn: 'MCN培训协议'
      },
      content: ''
    };
  },
  computed:{
    
  },
  components:{
  },
  mounted() {
    if(this.$route.params.t){
        this.t = this.$route.params.t;
    }else{
        this.t = 'user';
    }
      this.getData();
  },
  methods: {
    getData(){
        this.loading = true;
        let data = {type: this.t};
        this.$http.call(this.$http.api.agree,{
            params:data
        }).then(res=>{
            console.log(res);
            this.loading = false;
            let resp = res.data;
            if(resp.code=='0'){
                this.content = resp.content;
            }else{
                Toast(resp.data);
            } 
            
        },function(res){
            console.log('something goes wrong...');
            console.log(res);
        }).then(()=>{
            
        });
    },
  },
};
</script>
<style lang="less" scoped>
.agree {
    padding: 50px 15px;
}
</style>
